import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook4/image/imgPortadaEbook4.png"
import img1 from "@components/pageEbook/ebooks/ebook4/image/imgCard1.png"
import img2 from "@components/pageEbook/ebooks/ebook4/image/imgCard2.png"
import img3 from "@components/pageEbook/ebooks/ebook4/image/imgCard3.png"
import slider1 from "@components/pageEbook/ebooks/ebook4/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook4/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook4/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook4/image/imgSilder4.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Qué es marketing conversacional?
    </h1>
  </>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
      Con el auge de los medios digitales, si un negocio no responde
      adecuadamente los mensajes de sus clientes, ya no los podrá recuperar.
      <br /> <br />
      Por eso, en este e-book planteamos al marketing conversacional como uno de
      los ejes principales para fidelizar clientes, aumentar su retención y
      mejorar las ventas.
    </p>
  </div>
)

const textOne = (
  <p>
    El marketing conversacional es una estrategia que se enfoca en mantener
    diálogos naturales y cercanos con los clientes potenciales de un negocio.
    <br /> <br />
    La idea de su implementación está en establecer conexiones con los clientes
    potenciales mediante los canales de chat de un negocio.
  </p>
)
const textTwo = (
  <p>
    El marketing conversacional trae muchos beneficios en tu negocio ya que
    acompaña al cliente en cada etapa de su compra.
    <br /> <br />
    Por eso, se dice que su principal ventaja es que ayuda a establecer
    conexiones con los clientes para luego impulsar una conversión.
  </p>
)
const textThree = (
  <p>
    Con un chatbot se puede asegurar respuestas automatizadas las 24 horas del
    día. Sin importar que tengas agentes conectados.
  </p>
)

const data = {
  start: {
    support: "Ebook | Negocios",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-marketing",
    link: linkForm,
    href: "/ebook-4.pdf",
    brochure: "ebook_marketing_conversacional",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "¿Qué es marketing conversacional?",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Ventajas del marketing conversacional",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Chatbots: Herramienta clave del Marketing Conversacional",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Preparar a tu empresa antes de implementar el marketing conversacional.",
      },
      {
        key: 2,
        text: "Crear paso a paso una correcta estrategia de marketing conversacional.",
      },
      {
        key: 3,
        text: "Diferenciar las ventajas y desventajas que tiene el marketing conversacional.",
      },
      {
        key: 4,
        text: "Implementar chatbots como una herramienta clave para este tipo de estrategia.",
      },
      {
        key: 5,
        text: "Establecer conexiones con los clientes potenciales mediante los canales de chat.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
